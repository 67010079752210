const IconLineCircle: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12C0 5.37 5.37 0 12 0C18.63 0 24 5.37 24 12C24 18.63 18.63 24 12 24C5.37 24 0 18.63 0 12ZM19.6 11.56C19.6 8.27 16.28 5.6 12.2 5.6C8.12 5.6 4.8 8.27 4.8 11.56C4.8 14.51 7.43 16.98 10.99 17.44C11.23 17.49 11.56 17.6 11.64 17.8C11.71 17.99 11.69 18.28 11.66 18.46C11.66 18.46 11.57 18.98 11.55 19.09C11.52 19.28 11.4 19.82 12.19 19.49C12.98 19.16 16.45 17 18 15.22C19.07 14.05 19.59 12.87 19.59 11.55L19.6 11.56ZM10.66 10H10.15C10.07 10 10.01 10.07 10.01 10.15V13.46C10.01 13.54 10.07 13.61 10.15 13.61H10.66C10.74 13.61 10.8 13.54 10.8 13.46V10.15C10.8 10.07 10.74 10 10.66 10ZM14.25 10H13.71C13.63 10 13.56 10.07 13.56 10.15V12.11L12.02 10.06C12.02 10.06 12.02 10.05 12.01 10.05C12.01 10.05 12.01 10.05 12.01 10.04H11.47C11.39 10.04 11.32 10.11 11.32 10.19V13.5C11.32 13.58 11.39 13.65 11.47 13.65H12.01C12.09 13.65 12.16 13.58 12.16 13.5V11.54L13.7 13.59C13.7 13.59 13.72 13.62 13.74 13.63C13.74 13.63 13.74 13.63 13.75 13.63C13.76 13.63 13.78 13.63 13.79 13.63H14.33C14.41 13.63 14.48 13.56 14.48 13.48V10.17C14.48 10.09 14.41 10.02 14.33 10.02L14.25 10ZM9.45 12.77H8.02V10.15C8.02 10.07 7.95 10 7.87 10H7.34C7.26 10 7.19 10.07 7.19 10.15V13.45C7.19 13.45 7.21 13.53 7.23 13.55C7.26 13.58 7.29 13.59 7.33 13.59H9.44C9.52 13.59 9.59 13.52 9.59 13.44V12.91C9.59 12.83 9.52 12.76 9.44 12.76L9.45 12.77ZM17.05 10.83C17.13 10.83 17.2 10.76 17.2 10.68V10.15C17.2 10.07 17.13 10 17.05 10H14.94C14.94 10 14.86 10.02 14.84 10.04C14.81 10.07 14.8 10.1 14.8 10.14V13.44C14.8 13.44 14.82 13.52 14.84 13.54C14.87 13.57 14.9 13.58 14.94 13.58H17.05C17.13 13.58 17.2 13.51 17.2 13.43V12.9C17.2 12.82 17.13 12.75 17.05 12.75H15.62V12.19H17.05C17.13 12.19 17.2 12.12 17.2 12.04V11.51C17.2 11.43 17.13 11.36 17.05 11.36H15.62V10.8H17.05V10.83Z" />
  </svg>
);
IconLineCircle.displayName = 'IconLineCircle';
export default IconLineCircle;
