const IconFacebookCircle: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM13.2508 19.0558V12.5272H15.0529L15.2918 10.2773H13.2508L13.2539 9.15125C13.2539 8.56447 13.3096 8.25006 14.1524 8.25006H15.279V6H13.4766C11.3116 6 10.5495 7.0914 10.5495 8.92679V10.2776H9.2V12.5274H10.5495V19.0558H13.2508Z" />
  </svg>
);
IconFacebookCircle.displayName = 'IconFacebookCircle';
export default IconFacebookCircle;
