import React from 'react';

import Footer from '@/components/layouts/footer';
import ScrollTop from '@/components/layouts/scroll-to-top';
import {
  COPYRIGHT,
  MENU_CV_ABOUT,
  MENU_CV_BUYING_TOOLS,
  MENU_CV_CONTACT,
  MENU_CV_CONTENT_HUB_GROUP,
  MENU_CV_LIFE_CYCLE_SOLUTIONS,
  MENU_CV_MODEL_FOOTER,
  MENU_CV_OTHER_SERVICE,
  MENU_CV_OTHER_SERVICE_INSURANCE,
  MENU_CV_SERVICE,
  MENU_CV_SOCIAL,
  MENU_CV_SPECIAL_OFFER,
} from '@/contents/link';

const FooterCVGroup: React.FC = () => (
  <>
    <Footer
      web="cv"
      copyright={COPYRIGHT}
      buyingToolMenu={MENU_CV_BUYING_TOOLS}
      communicationChannels={MENU_CV_CONTACT}
      carModelMenu={MENU_CV_MODEL_FOOTER}
      serviceMenu={MENU_CV_SERVICE}
      offerMenu={MENU_CV_SPECIAL_OFFER}
      insuranceMenu={MENU_CV_OTHER_SERVICE_INSURANCE}
      contentHubMenu={MENU_CV_CONTENT_HUB_GROUP}
      otherServiceMenu={MENU_CV_OTHER_SERVICE}
      aboutMenu={MENU_CV_ABOUT}
      socialMenu={MENU_CV_SOCIAL}
      lifeCycleMenu={MENU_CV_LIFE_CYCLE_SOLUTIONS}
    />
    <ScrollTop />
  </>
);
FooterCVGroup.displayName = 'FooterCVGroup';
export default FooterCVGroup;
