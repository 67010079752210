import React, { memo } from 'react';

import { Icon } from '@/components/shared/element/icons';
import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { css, styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const FooterBuyingToolsHover = styled(Box, {
  clipPath: 'circle(0 at 50% 50%)',
});

const FooterBuyingToolsLinkStyle = css({
  position: 'relative',
  flexGrow: '1',
  flexBasis: '0',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '$space-1',
  textAlign: 'center',
  padding: '$space-3 $space-2',
  '@xs': {
    rowGap: '$space-2',
    padding: '$space-4',
  },
  '@md': {
    padding: '$space-6',
  },
  '@lg': {
    rowGap: '$space-3',
    alignItems: 'stretch',
    textAlign: 'left',
    padding: '$space-8 $space-6',
  },
  '@xl': {
    padding: '$space-10 $space-8',
  },
  '&:not(:last-child)': {
    borderRight: '1px solid $gray300',
  },
  '@hover': {
    '&:hover': {
      [`& ${FooterBuyingToolsHover}`]: {
        '--transition-duration': '0.5s',
        clipPath: 'circle(70.71068% at 50% 50%)',
        transition: 'clip-path var(--transition-duration)',
      },
    },
  },
});

interface FooterBuyingToolsProps {
  menu?: IMenu[];
}

const FooterBuyingTools: React.FC<FooterBuyingToolsProps> = memo(
  ({ menu = [] }: FooterBuyingToolsProps) => {
    return (
      <Section as="div" test="footer_buying_tools">
        <Box
          display="flex"
          border="y-1"
          borderColor="gray300"
          test={{
            dataTest: 'footer_buying_tools_items',
          }}
        >
          {menu.map((item, index) => (
            <Link
              key={index}
              className={FooterBuyingToolsLinkStyle()}
              href={item.url}
              target={item.target ?? undefined}
              tracking={{
                dataTrack: 'footer',
                dataTrackUrl: item.url,
                dataTrackText: item.label,
              }}
              test={{
                dataTest: 'footer_buying_tools_link',
              }}
            >
              <FooterBuyingToolsHover
                position="absolute"
                left="0"
                top="0"
                width="full"
                height="full"
                backgroundColor="gray200"
                pointerEvents="none"
              />

              {!!item?.icon && (
                <Icon
                  icon={item?.icon}
                  size={{ '@initial': 'base', '@md': 'md', '@lg': 'lg' }}
                  color="icon-red"
                />
              )}
              <Grid rowGap="0-5">
                <Text
                  size={{ '@initial': 'text-caption', '@md': 'text-body-2' }}
                  font="bold"
                  variant="text-gray-darkest"
                  wrap
                  dangerouslySetInnerHTML={{ __html: item.label }}
                  data-test="title"
                />
                {!!item.description && (
                  <Text
                    size="text-body-2"
                    variant="text-gray-dark"
                    css={{ '@maxlg': { display: 'none' } }}
                    wrap
                    dangerouslySetInnerHTML={{ __html: item.description }}
                    data-test="description"
                  />
                )}
              </Grid>
            </Link>
          ))}
        </Box>
      </Section>
    );
  }
);

FooterBuyingTools.displayName = 'FooterBuyingTools';
export default FooterBuyingTools;
