import { useMemo, useState } from 'react';

import FooterMenuGroupItems from '@/components/layouts/footer/footer-menu-group-items';
import FooterNavigationLink from '@/components/layouts/footer/footer-menu-link';
import { Grid } from '@/components/shared/layout/grid';
import { IMenu } from '@/types/layout/menu';

interface FooterNavigationItemProps {
  menu?: IMenu;
}

const SubmenuItem = ({
  menu,
  hasSubmenuLevel,
  toggle,
}: {
  menu: IMenu;
  hasSubmenuLevel: boolean;
  toggle?: boolean;
}) => {
  if (typeof toggle === 'boolean' && !toggle) return;

  if (hasSubmenuLevel) {
    return (
      <FooterMenuGroupItems
        items={menu.submenu as IMenu[]}
        noBreakLine={menu.noBreaklineSubmenu}
      />
    );
  }

  return (
    <Grid gap="1" data-test="footer_navigation_items">
      {menu.submenu?.map((subMenuEntry, index) => (
        <FooterNavigationLink key={index} {...subMenuEntry} />
      ))}
    </Grid>
  );
};

const FooterNavigationItem = ({ menu, ...rest }: FooterNavigationItemProps) => {
  const [showSubmenu, setShowSubmenu] = useState<boolean>(
    !!menu?.defaultStateToggleShowSubmenu
  );
  const parsedToggleSubmenuData = useMemo(
    () => (menu?.toggleShowSubmenu ? showSubmenu : undefined),
    [menu, showSubmenu]
  );
  const hasSubmenu = useMemo(() => !!menu?.submenu?.length, [menu]);
  const hasSubmenuLevel = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => hasSubmenu && menu.submenu?.filter((sub) => sub.submenu).length > 0,
    [menu, hasSubmenu]
  );
  const onToggle = () => {
    setShowSubmenu(!showSubmenu);
  };

  return (
    <Grid
      alignItems="start"
      rowGap="3"
      data-test="footer_navigation_group"
      {...rest}
    >
      {!!menu && !!menu.label && (
        <FooterNavigationLink
          key={menu.label}
          level="1"
          {...menu}
          toggle={parsedToggleSubmenuData}
          onToggle={
            parsedToggleSubmenuData !== undefined ? onToggle : undefined
          }
        />
      )}
      {!!hasSubmenu && (
        <SubmenuItem
          menu={menu as IMenu}
          hasSubmenuLevel={hasSubmenuLevel}
          toggle={parsedToggleSubmenuData}
        />
      )}
    </Grid>
  );
};

FooterNavigationItem.displayName = 'FooterNavigationItem';
export default FooterNavigationItem;
