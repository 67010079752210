import React, { memo } from 'react';

import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { Image } from '@/components/shared/media/image';
import { IMenu } from '@/types/layout/menu';

interface FooterCommunicationChannelsProps {
  menu?: IMenu;
}

const FooterCommunicationChannels: React.FC<FooterCommunicationChannelsProps> =
  memo(({ menu }: FooterCommunicationChannelsProps) => {
    return (
      <Section
        as="div"
        size="xs"
        backgroundColor="gray-light"
        test="footer_communication_channels"
      >
        <Container>
          <Flex
            alignItems="center"
            justifyContent={{ '@initial': 'center', '@md': 'between' }}
          >
            {!!menu?.label && (
              <Text
                as="h2"
                size="text-sub-h2"
                font="bold"
                css={{
                  display: 'none',
                  '@md': {
                    display: 'block',
                  },
                }}
                data-test="footer_communication_channels_title"
              >
                {menu.label}
              </Text>
            )}
            {!!menu?.submenu && menu?.submenu.length > 0 && (
              <Grid
                alignItems="center"
                css={{
                  gridTemplateColumns: 'repeat(2, minmax(auto, 200px))',
                  justifyContent: 'space-evenly',
                  columnGap: '$space-4',
                  width: '100%',
                  '@xs': {
                    columnGap: '$space-6',
                  },
                  '@md': {
                    justifyContent: 'flex-end',
                    width: 'auto',
                  },
                }}
              >
                {menu.submenu.map((item, index) => (
                  <Link
                    key={index}
                    href={item.url}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    target={item.target}
                    tracking={{
                      dataTrack: 'footer',
                      dataTrackText: item?.image?.alt,
                      dataTrackUrl: item.url,
                    }}
                    test={{
                      dataTest: `footer_communication_channels_link_${index}`,
                    }}
                  >
                    <Image
                      rootMargin="20% 0px 0px 0px"
                      src={item?.image?.src ?? ''}
                      alt={item?.image?.alt ?? ''}
                      width={item?.image?.width ?? 0}
                      height={item?.image?.height ?? 0}
                      layout="responsive"
                    />
                  </Link>
                ))}
              </Grid>
            )}
          </Flex>
        </Container>
      </Section>
    );
  });

FooterCommunicationChannels.displayName = 'FooterCommunicationChannels';
export default FooterCommunicationChannels;
