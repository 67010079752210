import { Fragment } from 'react';

import FooterNavigationLink from '@/components/layouts/footer/footer-menu-link';
import { Text } from '@/components/shared/element/text';
import { Grid } from '@/components/shared/layout/grid';
import { styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const StyledGroupItem = styled(Grid, {
  variants: {
    noBreakLine: {
      true: {},
      false: {
        '&:not(:last-child)': {
          paddingBottom: '$space-3',
          borderBottom: '1px solid $gray300',
        },
      },
    },
  },
});

interface FooterMenuGroupItemsProps {
  items: IMenu[];
  noBreakLine?: boolean;
}

const FooterMenuGroupItems = ({
  items,
  noBreakLine = false,
}: FooterMenuGroupItemsProps) => {
  return (
    <Grid
      alignItems="start"
      rowGap={noBreakLine ? 3 : 5}
      data-test="footer_navigation_group_items"
    >
      {items.map((subMenuEntry, index) => (
        <StyledGroupItem
          key={index}
          rowGap="1"
          data-test="footer_navigation_group_item"
          noBreakLine={noBreakLine}
        >
          {!!subMenuEntry.label && (
            <Text
              size="text-body-2"
              font="bold"
              data-test="footer_navigation_subtitle"
            >
              {subMenuEntry.label}
            </Text>
          )}
          {!!subMenuEntry.submenu && subMenuEntry.submenu.length > 0 && (
            <Grid gap="1" data-test="footer_navigation_items">
              {subMenuEntry.submenu.map((o, index) => (
                <Fragment key={index}>
                  {o.url ? (
                    <FooterNavigationLink {...o} />
                  ) : (
                    <>
                      {!!o.submenu &&
                        o.submenu.length > 0 &&
                        o.submenu.map((o, index) => (
                          <FooterNavigationLink key={index} {...o} />
                        ))}
                    </>
                  )}
                </Fragment>
              ))}
            </Grid>
          )}
        </StyledGroupItem>
      ))}
    </Grid>
  );
};

FooterMenuGroupItems.displayname = 'FooterMenuGroupItems';
export default FooterMenuGroupItems;
