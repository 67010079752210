import React from 'react';

import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';

interface FooterCopyrightProps {
  title?: string;
}

const FooterCopyright: React.FC<FooterCopyrightProps> = ({
  title,
}: FooterCopyrightProps) => {
  return (
    <Box
      marginTop="6"
      textAlign={{ '@lg': 'right' }}
      test={{
        dataTest: 'copyright',
      }}
    >
      <Text
        as="p"
        size="text-caption"
        variant="text-gray-dark"
        wrap
        dangerouslySetInnerHTML={{
          __html: title ?? '',
        }}
        data-test="copyright_title"
      />
    </Box>
  );
};

FooterCopyright.displayName = 'FooterCopyright';
export default FooterCopyright;
