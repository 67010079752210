import { FC } from 'react';

import { Icon } from '@/components/shared/element/icons';
import FacebookCircleIcon from '@/components/shared/element/icons/global/facebook-circle';
import InstagramIcon from '@/components/shared/element/icons/global/instagram-circle';
import LineCircleIcon from '@/components/shared/element/icons/global/line-circle';
import TiktokCircleIcon from '@/components/shared/element/icons/global/tiktok-circle';
import YoutubeCircleIcon from '@/components/shared/element/icons/global/youtube-circle';
import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { css } from '@/stitches.config';
import { type IMenu } from '@/types/layout/menu';

const FooterNavigationLinkStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$space-2',
  minHeight: '$size-6',
});

interface FooterNavigationLinkProps extends IMenu {
  level?: '1' | '2';
  toggle?: boolean;
  onToggle?: () => void;
}

const FooterNavigationLink: FC<FooterNavigationLinkProps> = ({
  level = '2',
  toggle,
  onToggle,
  ...rest
}: FooterNavigationLinkProps) => {
  const { label, url, target, icon } = rest;

  const getIconName = (icon: string) => {
    const iconLookup = {
      'tiktok-circle': <TiktokCircleIcon />,
      'facebook-circle': <FacebookCircleIcon />,
      'line-circle': <LineCircleIcon />,
      'instagram-circle': <InstagramIcon />,
      'youtube-circle': <YoutubeCircleIcon />,
    };
    return iconLookup[icon as keyof typeof iconLookup] || '';
  };

  return (
    <>
      {url ? (
        <Link
          href={url}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          target={target}
          hoverColor="red"
          className={FooterNavigationLinkStyle()}
          tracking={{
            dataTrack: 'footer',
            dataTrackText: label,
            dataTrackUrl: url,
          }}
          test={{
            dataTest: 'footer_navigation_link',
          }}
          {...rest}
        >
          {!!icon && (
            <Icon css={{ alignSelf: 'baseline' }}>{getIconName(icon)}</Icon>
          )}
          <Text
            size={level === '2' ? 'text-body-2' : 'text-body-1'}
            font={level === '2' ? 'base' : 'bold'}
            data-test="footer_navigation_title"
            wrap
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Link>
      ) : (
        <Box
          position="relative"
          paddingBottom="3"
          border="b-1"
          borderColor="gray300"
          display={toggle !== undefined ? 'flex' : undefined}
          justifyContent={toggle !== undefined ? 'between' : undefined}
          alignItems={toggle !== undefined ? 'center' : undefined}
          cursor={toggle !== undefined ? 'pointer' : 'default'}
          onClick={toggle !== undefined ? onToggle : undefined}
        >
          <Text
            size={level === '2' ? 'text-body-2' : 'text-body-1'}
            font={level === '2' ? 'base' : 'bold'}
            data-test="footer_navigation_title"
            wrap
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {toggle !== undefined && (
            <Icon
              css={{
                transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)',
                transitionProperty: 'transform',
                transitionDuration: 'var(--transition-duration)',
                transitionTimingFunction: 'var(--transition-easing)',
              }}
              icon="expand-more"
            />
          )}
        </Box>
      )}
    </>
  );
};

FooterNavigationLink.displayName = 'FooterNavigationLink';
export default FooterNavigationLink;
