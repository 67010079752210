import React, { memo, useCallback } from 'react';

import FooterCopyright from '@/components/layouts/footer/footer-copyright';
import FooterNavigationItem from '@/components/layouts/footer/footer-menu-item';
import { Container } from '@/components/shared/layout/container';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { styled } from '@/stitches.config';
import { FooterMenuProps, IMenu } from '@/types/layout/menu';

const FooterColumn = styled('div', {
  display: 'grid',
  alignItems: 'start',
  gap: '$space-6',
  '@lg': {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

const FooterNavigationGroupsStyle = styled('div', {
  display: 'grid',
  alignItems: 'start',
  gap: '$space-6',
  maxWidth: '$size-60',
});

const FooterNavigation = memo(
  ({
    carModelMenu,
    carModelMenu2024,
    carCVModelMenu,
    masterofallroadsMenu,
    offerMenu,
    serviceMenu,
    privilegeMenu,
    contentHubMenu,
    otherServiceMenu,
    aboutMenu,
    socialMenu,
    insuranceMenu,
    lifeCycleMenu,
    copyright,
    web,
  }: FooterMenuProps) => {
    const renderFooterNavigationItem = useCallback((menu: IMenu) => {
      return menu && <FooterNavigationItem menu={menu} />;
    }, []);

    return (
      <Section as="div" size="lg" test="footer_navigation">
        <Container>
          <Grid
            templateColumns="2"
            alignItems="start"
            gap={{ '@initial': '4', '@sm': '6' }}
            data-test="footer_navigation_groups"
          >
            {web === 'lcv' ? (
              <>
                <FooterColumn>
                  <FooterNavigationGroupsStyle>
                    {!!carModelMenu && renderFooterNavigationItem(carModelMenu)}
                    {!!carModelMenu2024 &&
                      renderFooterNavigationItem(carModelMenu2024)}
                    {!!carCVModelMenu &&
                      renderFooterNavigationItem(carCVModelMenu)}
                    {!!masterofallroadsMenu &&
                      renderFooterNavigationItem(masterofallroadsMenu)}
                  </FooterNavigationGroupsStyle>
                  <FooterNavigationGroupsStyle>
                    {!!offerMenu && renderFooterNavigationItem(offerMenu)}
                    {!!serviceMenu && renderFooterNavigationItem(serviceMenu)}
                    {!!privilegeMenu &&
                      renderFooterNavigationItem(privilegeMenu)}
                  </FooterNavigationGroupsStyle>
                </FooterColumn>
                <FooterColumn>
                  <FooterNavigationGroupsStyle>
                    {!!contentHubMenu &&
                      renderFooterNavigationItem(contentHubMenu)}
                    {!!otherServiceMenu &&
                      renderFooterNavigationItem(otherServiceMenu)}
                  </FooterNavigationGroupsStyle>
                  <FooterNavigationGroupsStyle>
                    {!!aboutMenu && renderFooterNavigationItem(aboutMenu)}
                    {!!socialMenu && renderFooterNavigationItem(socialMenu)}
                  </FooterNavigationGroupsStyle>
                </FooterColumn>
              </>
            ) : (
              <>
                <FooterColumn>
                  <FooterNavigationGroupsStyle>
                    {!!carModelMenu && renderFooterNavigationItem(carModelMenu)}
                  </FooterNavigationGroupsStyle>
                  <FooterNavigationGroupsStyle>
                    {!!serviceMenu && renderFooterNavigationItem(serviceMenu)}
                    {!!contentHubMenu &&
                      renderFooterNavigationItem(contentHubMenu)}
                    {!!offerMenu && renderFooterNavigationItem(offerMenu)}
                    {!!insuranceMenu &&
                      renderFooterNavigationItem(insuranceMenu)}
                  </FooterNavigationGroupsStyle>
                </FooterColumn>
                <FooterColumn>
                  <FooterNavigationGroupsStyle>
                    {!!lifeCycleMenu &&
                      renderFooterNavigationItem(lifeCycleMenu)}
                  </FooterNavigationGroupsStyle>
                  <FooterNavigationGroupsStyle>
                    {!!aboutMenu && renderFooterNavigationItem(aboutMenu)}
                    {!!socialMenu && renderFooterNavigationItem(socialMenu)}
                  </FooterNavigationGroupsStyle>
                </FooterColumn>
              </>
            )}
          </Grid>
          {!!copyright && <FooterCopyright title={copyright} />}
        </Container>
      </Section>
    );
  }
);

FooterNavigation.displayName = 'FooterNavigation';
export default FooterNavigation;
