import React from 'react';

import FooterBuyingTools from '@/components/layouts/footer/footer-buying-tools';
import FooterCommunicationChannels from '@/components/layouts/footer/footer-communication-channels';
import FooterNavigation from '@/components/layouts/footer/footer-menu';
import { Section } from '@/components/shared/layout/section';
import { FooterMenuProps, IMenu } from '@/types/layout/menu';

interface FooterProps extends FooterMenuProps {
  buyingToolMenu?: IMenu[];
  communicationChannels?: IMenu;
}

const Footer: React.FC<FooterProps> = ({
  buyingToolMenu,
  communicationChannels,
  ...rest
}: FooterProps) => {
  return (
    <Section as="footer" test="footer" data-myi-hide>
      <FooterBuyingTools menu={buyingToolMenu} />
      <FooterCommunicationChannels menu={communicationChannels} />
      <FooterNavigation {...rest} />
    </Section>
  );
};
Footer.displayName = 'Footer';
export default Footer;
