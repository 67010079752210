import React, { useEffect, useRef, useState } from 'react';

import { ButtonIcon } from '@/components/shared/buttons/button-icon';
import { Box } from '@/components/shared/layout/box';
import { styled } from '@/stitches.config';

interface ScrollToTopProps {
  children?: React.ReactNode;
}

const ScrollToTopStyled = styled(Box, {
  zIndex: '100',
});

const ScrollToTop: React.FC<ScrollToTopProps> = () => {
  const scrollButtonRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const scrolledPastFifth = window.pageYOffset > 500;
    const isScrollButtonEnabled =
      scrollButtonRef.current?.getAttribute('data-enable') !== null;

    setIsVisible(scrolledPastFifth && isScrollButtonEnabled);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ScrollToTopStyled
      ref={scrollButtonRef}
      position="fixed"
      right={{ '@initial': '6', '@sm': '10' }}
      bottom={{ '@initial': '6', '@sm': '10' }}
      data-cy="scroll-to-top"
      data-enable
    >
      {!!isVisible && (
        <ButtonIcon
          icon="arrow-upward"
          variant="button-icon-color-white"
          size="lg"
          shadow
          onClick={scrollToTop}
          title="Scroll to top"
        />
      )}
    </ScrollToTopStyled>
  );
};

export default ScrollToTop;
